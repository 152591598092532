import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';

const ThanksPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, follow" />
    </Helmet>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>We have received your message.</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default ThanksPage;
